<template>
    <div>
      <trademark-objection-to-opposing-view-status-list></trademark-objection-to-opposing-view-status-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  